import Layout from "../common/layout";
import img from "../assets/images/bg/roadOff.png";
import RoadmapWrapper from "./roadMap.style";

const Roadmap = () => {
  return (
    <Layout>
      <RoadmapWrapper>
        <div className="containerRoadmap">
          <img src={img} />
          <a href="/#roadmap">
            <span></span> COME BACK
          </a>
        </div>
      </RoadmapWrapper>
    </Layout>
  );
};

export default Roadmap;
