import Layout from "../common/layout";
import img from "../assets/images/bg/stories.png";
import StoryWrapper from "./story.style";

const Story = () => {
  return (
    <Layout>
      <StoryWrapper>
        <div className="containerStory">
          <img src={img} />
          <a href="/#roadmap">
            <span></span> COME BACK
          </a>
        </div>
      </StoryWrapper>
    </Layout>
  );
};

export default Story;
