import { useEffect, useState } from "react";
import { FaDiscord } from "react-icons/fa";
import NavWrapper from "./Header.style";

import logo1 from "../../../../assets/images/bg/logo.png";

import MobileMenu from "../mobileMenu/MobileMenu";
const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);
  return (
    <NavWrapper className="bithu_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="bithu_menu_sect">
          <div className="bithu_menu_left_sect">
            <div className="logo">
              <img src={logo1} />
            </div>
          </div>
          <div className="bithu_menu_right_sect ">
            <div className="bithu_menu_list">
              <ul>
                <li>
                  <a style={{ fontSize: 25 }} href="#home">
                    Home
                  </a>
                </li>
                <li>
                  <a style={{ fontSize: 25 }} href="#story">
                    Story
                  </a>
                </li>
                <li>
                  <a style={{ fontSize: 25 }} href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a style={{ fontSize: 25 }} href="#whitepaper">
                    Whitepaper
                  </a>
                </li>
                <li>
                  <a style={{ fontSize: 25 }} href="#team">
                    Team
                  </a>
                </li>
                {/*<li className="submenu">
                  <a href="# ">Pages +</a>
                  <div className="sub_menu_sect">
                    <ul className="sub_menu_list">
                      <li>
                        <a href="/">Home One</a>
                      </li>
                      <li>
                        <a href="/home-two">Home Two</a>
                      </li>
                      <li>
                        <a href="/home-three">Home Three</a>
                      </li>
                      <li>
                        <a href="/blogs">Latest Blog</a>
                      </li>
                      <li>
                        <a href="/post">Blog Details</a>
                      </li>
                    </ul>
                  </div>
               </li>*/}
              </ul>
            </div>
            <div className="bithu_menu_btns">
              <div className="login-box">
                <a href="https://discord.gg/SHEdskf8b">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <FaDiscord /> Join
                </a>
              </div>
              {/*<button className="menu_btn" onClick={() => handleMobileMenu()}>
                <MdNotes />
              </button>
              <Button sm variant="hovered" className="connect_btn">
                <a
                  href="https://discord.gg/bHU2dAZt"
                  style={{ color: "white" }}
                >
                  <FaDiscord /> Join
                </a>
              </Button>
          */}
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
