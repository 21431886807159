import styled from "styled-components";

const RoadmapWrapper = styled.section`
  /* Container needed to position the button. Adjust the width as needed */
  cursor: zoom-in;
  .containerRoadmap {
    position: relative;

    a {
      opacity: 1;
      position: absolute;
      top: 7%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 21%;
      line-height: 250%;
      color: black;
      background-image: linear-gradient(to left, #32ac73, #157346);
      text-align: center;
      font-size: 28px;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background-image: linear-gradient(to left, #157346, #32ac73);
        color: white;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .containerRoadmap {
      a {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .containerRoadmap {
      a {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .containerRoadmap {
      a {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .containerRoadmap {
      a {
        font-size: 9px;
      }
    }
  }
`;

export default RoadmapWrapper;
