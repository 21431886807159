import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Bn from "../myComponents/bn/Bn";

const HomeV1 = () => {
  return (
    <Layout>
      <Header />
      <Bn />
    </Layout>
  );
};

export default HomeV1;
