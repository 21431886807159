import styled from "styled-components";

const StoryWrapper = styled.section`
  /* Container needed to position the button. Adjust the width as needed */
  cursor: zoom-in;
  .containerStory {
    position: inherit;

    a {
      opacity: 0.65;
      position: absolute;
      top: 6%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18%;
      line-height: 230%;
      color: black;
      background-image: linear-gradient(to left, #846c53, #bcaa97);
      text-align: center;
      font-size: 28px;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background-image: linear-gradient(to left, #bcaa97, #846c53);
        color: white;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .containerStory {
      a {
        top: 3%;
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .containerStory {
      a {
        width: 15%;
        top: 2%;
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .containerStory {
      a {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .containerStory {
      a {
        font-size: 9px;
      }
    }
  }
`;

export default StoryWrapper;
