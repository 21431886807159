import styled from "styled-components";

const Banner1Wrapper = styled.section`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans");
  /* Container needed to position the button. Adjust the width as needed */

  .containerClay {
    position: relative;

    a {
      opacity: 1;
      position: absolute;
      top: 36%;
      left: 51%;
      transform: translate(-50%, -50%);
      width: 21%;
      line-height: 250%;
      color: black;
      background-image: linear-gradient(to left, #846c53, #bcaa97);
      text-align: center;
      font-size: 30px;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background-image: linear-gradient(to left, #bcaa97, #846c53);
        color: white;
      }
    }
  }
  .container3 {
    position: relative;
  }

  .container4 {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .containerRoadmap {
    position: relative;

    a {
      opacity: 1;
      position: absolute;
      top: 94%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 21%;
      line-height: 250%;
      color: white;
      background-image: linear-gradient(to left, #020024, #084e91);
      text-align: center;
      font-size: 28px;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background-image: linear-gradient(to left, #084e91, #020024);
        color: #fcb617;
      }
    }
  }

  .containerr {
    position: relative;

    a {
      opacity: 0.85;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 288px;
      height: 80px;
      color: white;
      background-image: linear-gradient(to bottom right, #f44d00, #fcaf00);
      text-align: center;
      line-height: 80px;
      font-size: 30px;
      border-radius: 7px;

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background: black;
        color: #ec4899;
      }
    }
  }

  .magicEden {
    width: 15%;
    margin-right: 0.5rem;
  }

  .road {
    width: 15%;
    margin-right: 0.5rem;
  }

  .containerF {
    position: relative;

    p {
      padding: 20px;
      background: #0000004a;
      opacity: 1;
      font-family: "Open Sans";
      font-style: normal;
      position: absolute;
      font-weight: 400;
      top: 30vw;
      left: 50%;
      width: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      font-size: 1.5vw;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    span {
      font-family: "GelioFasolada";
      color: #fcb20b;
      font-size: 2.2vw;
    }
  }

  .containerF2 {
    position: relative;

    p {
      background: #000000c4;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 20px;
      font-family: "Open Sans";
      font-style: italic;
      position: absolute;
      font-weight: 400;
      top: 56vw;
      left: 50%;
      width: 60%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      font-size: 1.5vw;
      border-radius: 7px;
    }

    span {
      font-family: "GelioFasolada";
      color: #fcb20b;
      font-size: 2.2vw;
    }
  }

  .containerFirst {
    position: relative;

    a {
      opacity: 0.85;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 21%;
      line-height: 250%;
      color: white;
      background-image: linear-gradient(to bottom right, #f44d00, #fcaf00);
      text-align: center;
      font-size: 30px;
      border-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:hover {
        opacity: 1;
        transition: 0.5s;
        background: black;
        color: #ec4899;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .containerFirst,
    .containerClay,
    .containerRoadmap {
      a {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .containerFirst,
    .containerClay,
    .containerRoadmap {
      a {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .containerFirst,
    .containerClay,
    .containerRoadmap {
      a {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .containerFirst,
    .containerClay,
    .containerRoadmap {
      a {
        font-size: 9px;
      }
    }
  }

  .text-block {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 400px;
    color: white;

    h1 {
      font-size: 70px;
    }
  }

  .textbutton {
    position: relative;
    top: 50%;
    width: 100%;

    button {
      margin: 0 auto;
      font-size: 22px;
      color: white;
      background-image: linear-gradient(to bottom right, #a855f7, #ec4899);
    }

    h2 {
      font-size: 100px;
    }

    h3 {
      font-size: 70px;
    }

    span {
      display: inline-block;
    }
  }
`;

export default Banner1Wrapper;
