import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import Roadmap from "../pages/roadMap.jsx";
import Story from "../pages/story.jsx";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/story" element={<Story />} />
    </Routes>
  );
}

export default App;
