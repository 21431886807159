import Banner1Wrapper from "./Bn.style";
import img1 from "../../assets/images/bg/SOLMYTHS (1).png";
import img2 from "../../assets/images/bg/SOLMYTHS (2).png";
import img3 from "../../assets/images/bg/SOLMYTHS (3).png";
import img4 from "../../assets/images/bg/SOLMYTHS (4).png";
import img5 from "../../assets/images/bg/SOLMYTHS (5).png";
import img6 from "../../assets/images/bg/SOLMYTHS (6).png";
import img7 from "../../assets/images/bg/SOLMYTHS (7)-min.png";
import img8 from "../../assets/images/bg/SOLMYTHS (8)-min.png";
import img9 from "../../assets/images/bg/SOLMYTHS (9)-min.png";
import img10 from "../../assets/images/bg/SOLMYTHS (10)-min.png";
import img11 from "../../assets/images/bg/FAQ-min.png";

import imm from "../../assets/me.svg";
import imRoad from "../../assets/road.svg";

import CarouselApp from "../../components/section/carousel/CorouselApp";

const Banner = () => {
  return (
    <Banner1Wrapper id="sec3">
      <div>
        <div id="home" onContextMenu={(e) => e.preventDefault()}>
          <img src={img1} className="im3" alt="NFT" />
        </div>

        <div className="containerFirst">
          <img src={img2} onContextMenu={(e) => e.preventDefault()} alt="NFT" />
          <a
            href="https://magiceden.io/drops/myts?tab=allDrops"
            target="_blank"
          >
            <img src={imm} className="magicEden" />
            <span></span> MAGIC EDEN
          </a>
        </div>

        <div className="containerF" onContextMenu={(e) => e.preventDefault()}>
          <img src={img3} className="im3" alt="NFT" />
        </div>

        <div className="containerF2" onContextMenu={(e) => e.preventDefault()}>
          <img src={img4} id="story" className="im3" alt="NFT" />
        </div>

        <div
          className="containerClay"
          id="story"
          onContextMenu={(e) => e.preventDefault()}
        >
          <img src={img5} alt="NFT" />
          <a href="/story" target="_blank">
            <span></span> FULL SIZE STORY
          </a>
        </div>

        <div onContextMenu={(e) => e.preventDefault()}>
          <img src={img6} className="im3" alt="NFT" />
        </div>

        <div
          className="containerRoadmap"
          onContextMenu={(e) => e.preventDefault()}
        >
          <img src={img7} alt="NFT" />
          <a id="roadmap" href="/roadmap" target="_blank">
            {/*<img src={imRoad} className="road" />*/}
            <span></span> EXPLORE OUR MAP
          </a>
        </div>
        <div onContextMenu={(e) => e.preventDefault()}>
          <img src={img8} className="im3" alt="NFT" />
        </div>

        <div className="containerr" id="whitepaper">
          <img src={img9} alt="NFT" />
          <div>
            <CarouselApp />
          </div>
        </div>

        <img
          src={img10}
          alt="NFT"
          id="team"
          className="im3"
          onContextMenu={(e) => e.preventDefault()}
        />
        <img
          src={img11}
          className="im3"
          alt="NFT"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </Banner1Wrapper>
  );
};

export default Banner;
