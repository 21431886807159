import Carousel from "./Carousel";

import "./Carousel.css";

const IMAGES = [
  { id: 1, src: "./1-min.png", title: "foo", description: "bar" },
  { id: 2, src: "./2-min.png", title: "foo", description: "bar" },
  { id: 3, src: "./3-min.png", title: "foo", description: "bar" },
  { id: 4, src: "./4-min.png", title: "foo", description: "bar" },
  { id: 5, src: "./5-min.png", title: "foo", description: "bar" },
  { id: 6, src: "./6-min.png", title: "foo", description: "bar" },
  { id: 7, src: "./7-min.png", title: "foo", description: "bar" },
  { id: 8, src: "./8-min.png", title: "foo", description: "bar" },
  { id: 9, src: "./9-min.png", title: "foo", description: "bar" },
  { id: 10, src: "./10-min.png", title: "foo", description: "bar" },
  { id: 11, src: "./11-min.png", title: "foo", description: "bar" },
  { id: 12, src: "./12-min.png", title: "foo", description: "bar" },
];

const Card = ({ imm }) => {
  return (
    <div>
      <img src={require(`${imm}`)} />
    </div>
  );
};

const CarouselApp = () => (
  <div className="tri">
    <Carousel>
      {IMAGES.map((image, i) => (
        <Card imm={image.src} />
      ))}
    </Carousel>
  </div>
);

export default CarouselApp;
